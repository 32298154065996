import { ModelBase } from './ModelBase'
import { ExtensionParticipation } from './ExtensionParticipation'
import { ExtensionVehicle } from './ExtensionVehicle'

export class ImpactWinner extends ModelBase {
  constructor (raw = {}, cache = {}) {
    super(raw, cache)

    this.login = this._str(raw.login)
    this.location = this._str(raw.location)
    this.lookingForWinner = Boolean(raw.lookingForWinner)

    this.invoiceDate = this._date(raw.invoiceDate)
    this.saleDate = this._date(raw.saleDate)

    this.currency = this._str(raw.currency || raw.cc).trim()
    this.invoiceTotal = this._num(raw.invoiceTotal)
    this.salePrice = this._num(raw.salePrice)

    const r11s = raw.relationships || {}

    this.participation = ModelBase.modelFromCache(
      ExtensionParticipation,
      r11s.participation || {},
      cache
    )

    this.vehicle = ModelBase.modelFromCache(
      ExtensionVehicle,
      r11s.vehicle || {},
      cache
    )
  }
}
