import { impactWonActions, impactWonGetters, impactWonMutations } from './types'
import { IMPACT_WON_PAGE_LIMIT } from '../constants'

import { LoneSdkCall } from 'Utils/LoneSdkCall'
import { sdk } from 'Services/shelfNetworkSdk'

const loneSdkCall = new LoneSdkCall()

export default {
  async [impactWonActions.LOAD_LIST] (
    { commit, dispatch },
    { action, opts }
  ) {
    commit(impactWonMutations.SET_IS_LOADING, true)
    commit(impactWonMutations.SET_PAGE_NUMBER, 0)
    commit(impactWonMutations.SET_LIST_OPTS, opts)

    await dispatch(action, opts)

    commit(impactWonMutations.SET_IS_LOADING, false)
  },

  async [impactWonActions.LOAD_INVOICES_LIST] ({ commit, getters }, opts) {
    const fetchFn = opts => loneSdkCall.takeLatest(
      sdk.impactAutoBackOffice.getInvoices({
        query: {
          ...opts,
          page: {
            limit: IMPACT_WON_PAGE_LIMIT,
            ...(opts.page || {}),
          },
          include: ['identity', 'identity.basics', 'participation', 'vehicle']
        }
      })
    )

    const { data, meta } = await fetchFn({
      ...opts,
      page: { number: getters[impactWonGetters.PAGE_NUMBER] }
    })

    commit(impactWonMutations.SET_LIST, data)
    commit(impactWonMutations.SET_TOTAL_COUNT, meta.totalCount || 0)
    commit(impactWonMutations.SET_FETCH_FN, fetchFn)
  },

  async [impactWonActions.LOAD_PICKUPS_LIST] ({ commit, getters }, opts) {
    const fetchFn = opts => loneSdkCall.takeLatest(
      sdk.impactAutoBackOffice.getPickups({
        query: {
          ...opts,
          page: {
            limit: IMPACT_WON_PAGE_LIMIT,
            ...(opts.page || {}),
          },
          include: ['identity', 'identity.basics', 'participation', 'vehicle']
        }
      })
    )

    const { data, meta } = await fetchFn({
      ...opts,
      page: { number: getters[impactWonGetters.PAGE_NUMBER] }
    })

    commit(impactWonMutations.SET_LIST, data)
    commit(impactWonMutations.SET_TOTAL_COUNT, meta.totalCount || 0)
    commit(impactWonMutations.SET_FETCH_FN, fetchFn)
  },

  async [impactWonActions.LOAD_EXPORT_LIST] ({ commit, getters }) {
    const opts = getters[impactWonGetters.LIST_OPTS]
    const fetchFn = getters[impactWonGetters.FETCH_FN]
    if (!fetchFn) return

    const EXPORT_PAGE_LIMIT = 100
    const list = []

    let pageNumber = 0
    while (true) {
      const { data } = await fetchFn({
        ...opts,
        page: {
          number: pageNumber++,
          limit: EXPORT_PAGE_LIMIT
        },
      })

      list.push(...data)
      if (data.length < EXPORT_PAGE_LIMIT) break
    }

    commit(impactWonMutations.SET_EXPORT_LIST, list)
  },

  async [impactWonActions.LOAD_MORE] ({ commit, getters }, opts) {
    const fetchFn = getters[impactWonGetters.FETCH_FN]
    const pageNumber = getters[impactWonGetters.PAGE_NUMBER]
    if (!fetchFn || pageNumber === -1) return

    commit(impactWonMutations.SET_IS_NEXT_LOADING, true)

    commit(impactWonMutations.SET_PAGE_NUMBER, pageNumber + 1)

    const { data } = await fetchFn({
      ...opts,
      page: { number: getters[impactWonGetters.PAGE_NUMBER] }
    })

    commit(impactWonMutations.PUSH_LIST, data)

    if (data.length < IMPACT_WON_PAGE_LIMIT) {
      commit(impactWonMutations.SET_PAGE_NUMBER, -1)
    }

    commit(impactWonMutations.SET_IS_NEXT_LOADING, false)
  },

  async [impactWonActions.UPDATE_LOT_SALE] (_, opts) {
    await sdk.impactAutoBackOffice.updateLotSale(opts)
  },

  async [impactWonActions.LOAD_MANAGED_LOGINS] ({ commit }) {
    const { data: config } = await sdk.impactAutoBackOffice.getConfig({
      query: {
        // HACK: Hardcode version for admin panel
        filter: { version: '333.0.0' }
      }
    })

    commit(
      impactWonMutations.SET_MANAGED_LOGINS,
      config.allowedToAssignTo
    )
  },

  [impactWonActions.SET_IS_LOADING] ({ commit }, value) {
    commit(impactWonMutations.SET_IS_LOADING, value)
  }
}
