export function genImpactWonState () {
  return {
    isLoading: true,
    isNextLoading: false,
    list: [],
    exportList: [],
    listOpts: {},
    totalCount: 0,
    pageNumber: 0,
    fetchFn: null,
    managedLogins: [],
  }
}

export default genImpactWonState
