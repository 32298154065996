import { impactWonMutations } from './types'

export default {
  [impactWonMutations.SET_IS_LOADING] (state, value) {
    state.isLoading = value
  },

  [impactWonMutations.SET_IS_NEXT_LOADING] (state, value) {
    state.isNextLoading = value
  },

  [impactWonMutations.SET_LIST] (state, value) {
    state.list = value
  },

  [impactWonMutations.SET_EXPORT_LIST] (state, value) {
    state.exportList = value
  },

  [impactWonMutations.SET_LIST_OPTS] (state, value) {
    state.listOpts = value
  },

  [impactWonMutations.SET_TOTAL_COUNT] (state, value) {
    state.totalCount = value
  },

  [impactWonMutations.PUSH_LIST] (state, value) {
    state.list = state.list.concat(value)
  },

  [impactWonMutations.SET_PAGE_NUMBER] (state, value) {
    state.pageNumber = value
  },

  [impactWonMutations.SET_FETCH_FN] (state, value) {
    state.fetchFn = value
  },

  [impactWonMutations.SET_MANAGED_LOGINS] (state, value) {
    state.managedLogins = value
  },
}
