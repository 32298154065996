import { ImpactWinner } from 'Models/ImpactWinner'
import { impactWonGetters } from './types'

export default {
  [impactWonGetters.IS_LOADING]: state => state.isLoading,
  [impactWonGetters.IS_NEXT_LOADING]: state => state.isNextLoading,

  [impactWonGetters.PAGE_NUMBER]: state => state.pageNumber,
  [impactWonGetters.FETCH_FN]: state => state.fetchFn,

  [impactWonGetters.LIST]: state => state.list
    .map(item => new ImpactWinner(item)),
  [impactWonGetters.TOTAL_COUNT]: state => state.totalCount,

  [impactWonGetters.EXPORT_LIST]: state => state.exportList
    .map(item => new ImpactWinner(item)),
  [impactWonGetters.LIST_OPTS]: state => state.listOpts,

  [impactWonGetters.MANAGED_LOGINS]: state => state.managedLogins,
}
